








import Vue from "vue";
import Component from "vue-class-component";
import LayoutMain from "./layouts/LayoutMain.vue";

@Component({
  components: { LayoutMain },
  name: "App",
})
export default class extends Vue {}
