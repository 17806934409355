








































































import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "WeatherCard",
})
export default class extends Vue {
  labels = ["SU", "MO", "TU", "WED", "TH", "FR", "SA"];
  time = 0;
  forecast = [
    { day: "Tuesday", icon: "mdi-white-balance-sunny", temp: "24\xB0/12\xB0" },
    {
      day: "Wednesday",
      icon: "mdi-white-balance-sunny",
      temp: "22\xB0/14\xB0",
    },
    { day: "Thursday", icon: "mdi-cloud", temp: "25\xB0/15\xB0" },
  ];
}
