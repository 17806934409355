


































import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "SparkLine",
})
export default class extends Vue {
  labels = ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"];
  value = [200, 675, 410, 390, 310, 460, 250, 240];
}
