


















import Vue from "vue";
import Component from "vue-class-component";
import DataTable from "./DataTable.vue";
import SparkLine from "./SparkLine.vue";
import WeatherCard from "./WeatherCard.vue";

@Component({
  components: { DataTable, WeatherCard, SparkLine },
})
export default class extends Vue {}
