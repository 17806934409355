import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";

/**
 * Import style overrides.
 * Variables are picked up by vuetify-loader automatically
 */
import "./styles/overrides.scss";

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
