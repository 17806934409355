














































import Vue from "vue";
import Component from "vue-class-component";
import { MenuItem, menuItems } from "./menuItems";

@Component({
  name: "LayoutMain",
})
export default class extends Vue {
  darkMode = false;

  get menuItems(): MenuItem[] {
    return menuItems;
  }
}
