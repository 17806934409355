import { Route } from "vue-router";

export type MenuItem = {
  icon: string;
  label: string;
  to?: string | Route;
};

export const menuItems: MenuItem[] = [
  {
    icon: "mdi-home",
    label: "Home",
  },
  {
    icon: "mdi-home",
    label: "Home",
  },
  {
    icon: "mdi-home",
    label: "Home",
  },
];
